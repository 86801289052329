/* React Date Picker & Calendar Styling */
.react-month-picker,
.react-month-picker input {
  height: 100%;
}
.react-month-picker:visited,
.react-month-picker input:visited,
.react-month-picker:focus,
.react-month-picker input:focus,
.react-month-picker:focus-visible,
.react-month-picker input:focus-visible {
  border: 1px solid #fff;
}
.react-month-picker .col_mp:hover,
.react-month-picker .selected_cell {
  background-color: #7E4E60 !important;
}
.react-month-picker .selected_date_mp {
  color: #7E4E60 !important;
  line-height: 28px;
}
.react-month-picker .selected_date_mp:hover {
  color: #fff !important;
}
.month-input {
  text-align: center !important;
  width: 100% !important;
}
.month_name {
  position: absolute;
  right: 0;
  margin-left: 50%;
  font-size: 28px;
}
.react-month-picker {
  margin-top: auto;
  margin-bottom: auto;
}
.react-date-picker__inputGroup {
  padding: 0 0.5rem !important;
}
.react-date-picker__inputGroup__input,
.react-date-picker__inputGroup__divider,
.react-date-picker__inputGroup__leadingZero {
  color: #fff;
}
div.react-date-picker__wrapper {
  border: 1;
  padding: 1px;
  background-color: #482728;
  border-radius: 10px;
  font-size: 20px;
}
.react-date-picker__calendar .react-calendar {
  background-color: #fff;
}
.react-date-picker {
  width: 100% !important;
  height: 100% !important;
}
.react-calendar__tile {
  color: #7E4E60;
  font-size: 16px;
}
.react-calendar__tile--now {
  background-color: #7E4E60;
  color: #fff;
  font-size: 18px;
}
button.react-calendar__tile--active {
  background-color: #482728;
  color: #fff;
}
button.react-calendar__tile--active:hover {
  background-color: #7E4E60;
  color: #fff;
}
button.react-calendar__tile--active:active {
  background-color: #7E4E60;
  color: #fff;
}
.react-calendar__tile--range,
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd,
.react-calendar__tile--rangeBothEnds,
.react-calendar__month-view__days__day {
  color: #7E4E60;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #482728;
}
.react-calendar__month-view__weekdays__weekday {
  color: #7E4E60;
  font-size: 12px;
}
.react-calendar__navigation__label__labelText {
  color: #7E4E60;
  font-size: 18px;
}
.react-calendar__tile,
.react-calendar__year-view__months__month {
  padding: 20px !important;
}
button.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__year-view__months__month {
  background-color: #482728;
  color: #fff;
}
.react-calendar__tile--now {
  background-color: #7E4E60 !important;
  color: #fff;
}
.react-calendar__tile--active {
  background-color: #7E4E60;
  color: #fff;
}
.react-calendar__navigation__arrow,
.react-calendar__navigation__prev-button,
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next-button,
.react-calendar__navigation__next2-button {
  color: #7E4E60;
}
.react-calendar__month-view__days__day {
  color: #7E4E60;
}
.react-calendar__month-view__days__day:disabled,
.react-calendar__navigation__next-button:disabled,
.react-calendar__navigation__next2-button:disabled {
  color: #c6c7c8;
}
.react-calendar__month-view__days__day--weekend {
  color: #fff;
  background-color: #61dafb;
}
.calendar-container {
  top: 25px !important;
  right: -45px !important;
  width: 175px;
}
/* .react-date-picker__inputGroup input {
  color: var(--bs-light) !important;
} */
.datePicker {
  font-size: 26px;
  color: #fff;
  background-color: #482728;
  border-radius: 10px;
}
.calendar-popup {
  position: absolute;
  z-index: 1000;
  top: 14%;
  right: 10%;
}
.disabled-month {
  color: #c6c7c8;
}
.calendar-popup1 {
  position: absolute;
  z-index: 1000;
  top: 8%;
  right: 5%;
}
@media (max-width: 767px) {
  .react-date-picker {
    height: 131% !important;
  }
}
.react-datetime-picker__inputGroup {
  border-radius: 10px;
  padding-right: 0.5rem;
  flex-grow: 0 !important;
}
.react-datetime-picker__wrapper {
  display: flex;
  justify-content: end;
  flex-grow: 1;
  border: thin solid #c4c0c0 !important;
  gap: 6px;
  border-right: none !important;
  border-top: none !important;
  border-left: none !important;
}
.react-datetime-picker__inputGroup > input {
  color: #0b0909 !important;
  background: transparent;
  border: 0;
  font-size: large;
}
.react-datetime-picker {
  width: 100%;
}
.react-datetime-picker__inputGroup__input react-datetime-picker__inputGroup__amPm {
  color: black !important;
  background-color: #7e4e60 !important;
}
.react-datetime-picker__inputGroup__leadingZero {
  color: black;
  font-size: large !important;
}
.react-calendar__tile--now {
  color: #fff !important;
}
.react-datetime-picker__inputGroup__divider {
  color: black;
}
.ant-picker {
  border: 1px solid #5f5b5b;
}
.ant-picker:hover {
  border-color: #5f5b5b !important;
}
.css-dev-only-do-not-override-cdzvx5 {
  border-radius: none !important;
}
.anticon-clock-circle {
  color: #5f5b5b;
}
:where(.css-dev-only-do-not-override-cdzvx5).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  ) .ant-picker-cell-inner {
  background-color: #7e4e60;
  border-color: #7e4e60;
}
:where(.css-cdzvx5).ant-picker-dropdown .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #7e4e60 !important;
}
.ant-picker-cell-inner:hover {
  background-color: #7e4e60 !important;
}
.ant-picker-cell-inner::before {
  border: 2px solid #7e4e60;
}
.ant-picker-time-panel-cell-inner:hover {
  background-color: #7e4e60 !important;
  color: white !important;
}
:where(.css-dev-only-do-not-override-cdzvx5).ant-picker-dropdown .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: #7e4e60 !important;
  color: white !important;
}
.ant-btn {
  background-color: #7e4e60 !important;
}
.ant-btn:hover {
  background-color: #7e4e60;
}
:where(.css-dev-only-do-not-override-cdzvx5).ant-picker {
  border-radius: 0px;
}
.ant-picker {
  border-left: none !important;
}
.ant-picker-input > input {
  font-size: 1.2rem !important;
  color: black !important;
}
.ant-picker-input > input:focus-within {
  border-color: black;
  color: black !important;
}
.ant-picker-input > input:focus {
  box-shadow: none !important;
}
:where(.css-dev-only-do-not-override-cdzvx5).ant-picker-outlined:focus,
:where(.css-dev-only-do-not-override-cdzvx5).ant-picker-outlined:focus-within {
  box-shadow: none !important;
  border-color: gray !important;
}
.ant-picker-outlined {
  border-top: none !important;
  border-right: none !important;
  border-color: #808080 !important;
}
:where(.css-cdzvx5).ant-picker {
  border-radius: 0px !important;
}
:where(.css-cdzvx5).ant-picker .ant-picker-input > input:focus {
  box-shadow: none !important;
}
:where(.css-xex5fb).ant-picker-dropdown .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: #7e4e60 !important;
}
:where(.css-dev-only-do-not-override-cdzvx5).ant-picker {
  border-radius: 0px !important;
}
:where(.css-xex5fb).ant-picker {
  border-radius: 0px !important;
}
.ant-picker {
  border-radius: 0px !important;
  box-shadow: none !important;
}
.ant-picker .ant-picker-input > input:focus {
  box-shadow: none !important;
}
:where(.css-xex5fb).ant-picker-outlined:focus,
:where(.css-xex5fb).ant-picker-outlined:focus-within {
  box-shadow: none !important;
}
.ant-picker-outlined:focus-within {
  box-shadow: none !important;
}
body {
  margin: 0;
  font-family: "Segoe UI", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}
.btn-app {
  background-color: #7E4E60;
  color: #fff;
}
.btn-app:hover {
  background-color: #482728;
  color: #fff;
}
.btn-outline-app {
  background-color: #fff;
  color: #7E4E60;
  border-color: #7E4E60;
  font-size: large;
}
.btn-outline-app:hover {
  background-color: #7E4E60;
  color: #fff;
}
.border-app {
  background-color: #7E4E60;
  border: 2px solid #7E4E60;
}
.App {
  text-align: center;
}
.cursor {
  cursor: pointer;
}
.logo {
  width: 64px;
  height: 64px;
  pointer-events: none;
}
.carousel-image {
  width: 100%;
  height: 500px;
}
.App-header {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
}
.app-container {
  color: #000;
  opacity: 1;
}
.bg-app {
  background-color: #7E4E60;
}
.border-app {
  border-color: #7E4E60;
  border-width: 2px;
  border-style: solid;
}
.text-app {
  color: #7E4E60;
  font-style: italic;
  font-weight: bold;
}
.App-link {
  color: #61dafb;
}
.background {
  background-image: url("assets/images/background.png");
  background-repeat: repeat-y;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.background-scroll {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}
.cashbook-scroll {
  height: calc(100vh - 1300px) !important;
  padding-bottom: 7rem !important;
}
.scrollbar-design {
  /* scrollbar-color: var(--primary) #e0e0e0; */
  scrollbar-width: thin;
}
.btn.position-absolute {
  z-index: 3;
}
.form-control {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0.5px;
  padding: 10px;
  border-radius: 0px;
  font-size: 18px;
}
.form-control:focus {
  border-color: #c6c7c8;
  box-shadow: 0 0 0 0.25rem #F0A8AD;
}
.bi-paperclip::before {
  transform: rotate(45deg);
}
.uploadbillpreview {
  width: 25%;
  height: auto;
  max-height: 60vh;
}
.upload-preview-remover {
  margin-right: -0.8rem;
}
.home-datepicker {
  height: fit-content;
}
.text-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}
.border-width-med {
  border-width: medium;
}
.khatabook-background {
  background-color: #fff !important;
  position: relative !important;
  min-height: 300px;
}
/* .row{
  margin-right: calc(-.52 * var(--bs-gutter-x)) !important;
} */
.dropdown-toggle::after {
  content: none;
}
.bg-not-transparent-on-hover {
  background: transparent !important;
  border: none !important;
}
.bg-not-transparent-on-hover:hover {
  background: #c6c7c8 !important;
  border: none !important;
}
.bg-not-transparent-on-hover:active {
  background: #c6c7c8 !important;
  border: none !important;
}
.bg-not-transparent-on-hover:visited {
  background: #c6c7c8 !important;
  border: none !important;
}
.text-decoration-line-through.text-black div a {
  text-decoration: line-through !important;
  color: #000 !important;
}
#txn-success-dialog {
  z-index: 20;
  width: 20vh;
  min-width: 200px;
  height: 20vh;
}
#txn-success-dialog div span i {
  font-size: 10rem;
}
.background-blurry {
  background: #ffffff1d;
}
.animate__animated.animate__slow {
  -webkit-animation-duration: calc(1s * 3) !important;
  animation-duration: calc(1s * 3) !important;
  -webkit-animation-duration: calc(var(--animate-duration) * 3) !important;
  animation-duration: calc(var(--animate-duration) * 3) !important;
}
.Warning-prompt {
  height: 65%;
}
.label {
  opacity: 0.3;
  height: 50px;
  width: 150px;
  color: #000;
  font-size: 20px;
  z-index: 1;
}
.z-index-1 {
  z-index: 1;
}
.accountsearch {
  display: block;
  font-weight: 600;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  opacity: 1;
  cursor: text;
}
.bi-funnel::before {
  box-sizing: border-box;
  padding-top: 0.35rem;
}
/* .closedAccountSwitch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 34px; 
  margin-left: 15px;
} */
.closedAccountSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.end-0-search {
  right: 42px !important;
}
.khataFilter {
  /* position: absolute;
  top: 0;
  right: 0; */
  /* margin-top: 3rem;
  margin-right: 2rem;
  height: 40px;
  width: 250px; */
  color: var(--black);
  z-index: 9;
}
.khataFilter .react-month-picker {
  width: auto;
}
.low-z-index {
  z-index: 1 !important;
}
.high-z-index {
  z-index: 2 !important;
}
.height-full {
  height: 100vh;
}
.table > :not(caption) > * > * {
  border-bottom-width: 0;
}
input.btn-outline-danger {
  --bs-btn-color: #7E4E60 !important;
  --bs-btn-border-color: #7E4E60 !important;
  --bs-btn-hover-color: #7E4E60 !important;
  --bs-btn-hover-bg: #fff !important;
  --bs-btn-hover-border-color: #7E4E60 !important;
  --bs-btn-focus-shadow-rgb: 220, 53, 69 !important;
  --bs-btn-active-color: #7E4E60 !important;
  --bs-btn-active-bg: #fff !important;
  --bs-btn-active-border-color: #7E4E60 !important;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020 !important;
  --bs-btn-disabled-color: #7E4E60 !important;
  --bs-btn-disabled-bg: transparent !important;
  --bs-btn-disabled-border-color: #7E4E60 !important;
  --bs-gradient: none !important;
}
.ff-serif {
  font-family: serif;
}
/* No Khata View - Start */
.nokhata {
  height: 750px;
  width: 100%;
  text-align: center;
  background-color: #fff;
}
.btn-animation {
  background-color: #7E4E60;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.btn-animation:hover {
  background-color: #7E4E60;
  transform: scale(1.05);
}
/* No Khata View - End */
.min_h_35 {
  min-height: 35vh;
}
.image-muted {
  filter: grayscale(40%);
}
.login-background {
  background-image: url("assets/images/login_bg.jpg");
  background-size: cover;
}
.resend-otp {
  font-size: 0.8rem;
}
.nav-link.active,
.active-link.active {
  text-decoration: underline;
  font-weight: bold;
}
.login-flag {
  width: 15px;
  height: auto;
  border-radius: 50%;
  margin-bottom: 2px;
}
.managercontacts {
  cursor: pointer;
  transition: 200ms ease-in-out;
  border-radius: 10px;
}
/* .managercontacts:hover{
  background-color: red;
} */
.manageranker {
  text-decoration: none;
  color: #000;
}
.manageranker:hover {
  text-decoration: underline;
}
.managerprofile {
  border-radius: 100%;
  object-fit: cover;
  width: 80px;
  height: 80px;
  aspect-ratio: 1/1;
  margin-right: 15px;
}
.cards {
  display: flex;
  flex-direction: column;
  align-items: left;
}
.fs-smaller {
  font-size: smaller;
}
.reminderAlertBG {
  background-color: #c6c7c8 !important;
}
.top-35 {
  top: 35%;
}
.dropdown-item:active {
  background-color: #7E4E60;
}
.dropdown-item:hover {
  background-color: #7E4E60;
  color: #fff;
}
#Loadingdialog {
  background-color: transparent;
  border: 0;
}
#successdialog {
  background-color: transparent;
  border: 0;
}
dialog[open] {
  opacity: 1;
  pointer-events: inherit;
}
dialog {
  opacity: 0;
  transition: opacity 0.5s;
}
dialog:focus-visible {
  outline: none !important;
}
.spinner-border.slow-animation {
  --bs-spinner-animation-speed: 1.5s;
}
input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -5px;
  left: -1px;
  position: relative;
  background-color: #c6c7c8;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #fff;
}
input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -5px;
  left: -1px;
  position: relative;
  background-color: #7E4E60;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #fff;
}
.rotate-180 {
  transform: rotate(180deg) !important;
  display: block;
}
.rotate-0 {
  transform: rotate(0deg) !important;
  display: block;
}
.formtype_transition i {
  transition: all 1s ease-in-out;
}
.public-view-scroll {
  height: Calc(46vh);
  overflow-y: scroll;
}
.radius-rounded {
  border-radius: 30%;
}
.profile-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  margin: 0px 4px;
}
.form-check-input:checked {
  background-color: #7E4E60;
  border-color: #7E4E60;
}
.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem #F0A8AD;
  border-color: #7E4E60;
}
.fast-animation {
  animation-duration: 0.1s !important;
  /* Adjust the duration as needed */
  /* z-index: -1000 !important; */
}
.sticky-bottom-drawer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.status-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  /* Ensure it's on top of other elements */
  display: flex;
  justify-content: center;
  align-items: center;
}
/* enable blur effect */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1025;
}
@media (max-width: 767px) {
  .background {
    background-size: contain;
    margin: 0px;
  }
  .cashbook-rightarrow-position {
    top: 0% !important;
    right: 3vw;
  }
  .cashbook-leftarrow-position {
    top: 0% !important;
  }
  .khatabook-accounts-btns svg {
    font-size: 1.3rem;
  }
  .fs-xmd-smaller {
    font-size: smaller !important;
  }
  .offcanvas {
    --bs-offcanvas-width: 100% !important;
    padding: 0px;
  }
  #delete-confirm-dialogue {
    width: 60vw;
    min-width: 10rem;
    max-width: 20rem;
    height: 20vh;
    min-height: 4rem;
    max-height: 8rem;
  }
}
@media (min-width: 768px) {
  .cashbook-rightarrow-position {
    right: 0;
    top: 40vh;
  }
  .cashbook-leftarrow-position {
    left: 0;
    top: 40vh;
  }
  .offcanvas {
    --bs-offcanvas-width: 32.9% !important;
    padding: 0px;
  }
  .w-md-50 {
    width: 50% !important;
  }
  #delete-confirm-dialogue {
    width: 40vw;
    min-width: 10rem;
    max-width: 20rem;
    height: 30vh;
    min-height: 4rem;
    max-height: 8rem;
  }
}
.react-international-phone-input-container .react-international-phone-input {
  width: 100%;
  border: 0px !important;
  font-size: 16px !important;
}
.react-international-phone-country-selector-button {
  border: 0px !important;
}
.profileImg {
  aspect-ratio: 9 / 9;
  object-fit: cover;
}
.txnAttachment {
  aspect-ratio: 16 / 9;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
  width: 300px;
}
.txnAttachment:hover {
  transform: scale(1.05);
}
